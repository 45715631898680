import './App.css';
import MyRouter from './routes/MyRouter';

function App() {
  return (
    <MyRouter />
  );
}

export default App;
